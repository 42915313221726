



















































































import { Component, Vue } from 'vue-property-decorator'
import CudosToken from '@/components/CudosToken.vue'
import { Getter } from 'vuex-class'
import { ethers } from 'ethers'
import TransactionIndicator from '@/components/TransactionIndicator.vue'

const namespace = 'wallet'

@Component({
  name: 'RequestToBecomeAValidator',
  components: {
    CudosToken,
    TransactionIndicator
  }
})
export default class RequestToBecomeAValidator extends Vue {
  @Getter('userValidatorObject', { namespace: 'wallet' }) userValidatorObject!: any
  @Getter('allContracts', { namespace }) allContracts!: any
  @Getter('stakingRewardParams', { namespace: 'wallet' }) stakingRewardParams!: any

  validator: any = {}
  valid = true
  minStakingAmount = '2000000000000000000000000'
  firstName = ''
  lastName = ''
  email = ''
  message = ''

  async mounted (): Promise<void> {
    this.validator = await this.userValidatorObject
    if (this.validator) {
      await this.$router.push({ name: 'validators' })
    }
    const stakingReward = await this.stakingRewardParams
    this.minStakingAmount = stakingReward.minRequiredStakingAmountForServiceProviders
  }

  nameRules = [
    (v: string): boolean | string => !!v || 'Name is required',
    (v: string): boolean | string => (v && v.length > 0) || 'Name is required'
  ]

  emailRules = [
    (v: string): boolean | string => !!v || 'E-mail is required',
    (v: string): boolean | string => /.+@.+\..+/.test(v) || 'E-mail must be valid'
  ]

  validate (): boolean {
    const form = this.$refs.form as HTMLFormElement
    this.valid = form.validate()
    return this.valid
  }

  async submitRequest () {
    if (!this.validate()) {
      return
    }
    const from = document.createElement('form')
    this.post('https://app.getresponse.com/add_subscriber.html', this.setFormData())
  }

  setFormData (): any {
    const formData: any = {}
    formData.honey = ''
    formData.campaign_token = 'ahXy5'
    formData.start_day = '0'
    formData['form-name'] = 'contact'
    formData.custom_subject = 'Become+a+Validator'
    formData.thankyou_url = this.redirectUrl()
    formData.first_name = this.firstName
    formData.last_name = this.lastName
    formData.email = this.email
    formData.custom_comment = this.message
    return formData
  }

  redirectUrl (): string {
    return document.location.protocol + '//' + document.location.host + '/validators/request-for-validator/success'
  }

  formatEther (gwei: any) {
    return ethers.utils.formatEther(gwei)
  }

  post (path: string, params: any[], method = 'post') {
    // The rest of this code assumes you are not using a library.
    // It can be made less verbose if you use one.
    const form = document.createElement('form')
    form.method = method
    form.action = path

    for (const key in params) {
      // eslint-disable-next-line no-prototype-builtins
      if (params.hasOwnProperty(key)) {
        const hiddenField = document.createElement('input')
        hiddenField.type = 'hidden'
        hiddenField.name = key
        hiddenField.value = params[key]

        form.appendChild(hiddenField)
      }
    }

    document.body.appendChild(form)
    form.submit()
  }
}
