








































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import CudosToken from '@/components/CudosToken.vue'
import { Action, Getter } from 'vuex-class'
import { BigNumber, ethers } from 'ethers'
import TransactionIndicator from '@/components/TransactionIndicator.vue'

@Component({
  name: 'ValidatorExited',
  components: {
    CudosToken, TransactionIndicator
  }
})
export default class ValidatorExited extends Vue {
  @Getter('cudosBalance', { namespace: 'wallet' }) cudosBalance!: number
  @Getter('userValidatorObject', { namespace: 'wallet' }) userValidatorObject!: any
  @Getter('stakingRewardParams', { namespace: 'wallet' }) stakingRewardParams!: any
  @Action('connectToServiceProvider', { namespace: 'wallet' }) connectToServiceProvider!: any
  @Getter('allContracts', { namespace: 'wallet' }) allContracts!: any
  @Getter('blockNumber', { namespace: 'wallet' }) blockNumber!: any

  withdrawAmount: any = 0
  stakingAmount: any = 0
  min = 0.000
  max = 0.000
  increment = 1
  currentBlock = Infinity
  stakingValid = true
  withdrawalValid = true
  pendingRewards = '-'
  validator: any = {}
  serviceProviderContract: any
  minStakingAmount = '2000000000000000000000000'
  maxStakingAmount = '1000000000000000000000000000'
  // @ts-ignore
  availableForWithdrawal: BigNumber = BigNumber.from(0)
  canWithdrawal = true
  withdrawalAmount: any = '0'
  withdrawalPending = true

  stakingRules = [
    (v: number): boolean | string => !!v || 'Amount is required',
    (v: any): boolean | string => (v && !isNaN(parseFloat(v))) || 'Amount must be a number',
    (v: number): boolean | string => (v && this.checkMax(v)) || 'You don\'t have sufficient balance',
    (v: number): boolean | string => (v && this.checkMin(v)) || 'You must stake a minimum amount',
    (v: number): boolean | string => (v && this.checkMaxValidatorStakeAmount(v)) || 'You cannot stake above the max staking amount'
  ]

  withdrawRules = [
    (v: number): boolean | string => !!v || 'Amount is required',
    (v: any): boolean | string => (v && !isNaN(parseFloat(v))) || 'Amount must be a number',
    (v: number): boolean | string => (v && this.checkMin(v)) || 'You must stake a minimum amount',
    (v: number): boolean | string => (v && this.checkMaxWithdraw(v)) || 'You cannot withdraw that amount'
  ]

  validateStaking (): boolean {
    const form = this.$refs.stakingForm as HTMLFormElement
    if (!form) {
      return false
    }
    const valid = form.validate()
    this.stakingValid = valid
    return valid
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  actionFunction: () => Promise<void> = async () => {}

  // This is gross, needs looking at
  @Watch('stakingAmount')
  handlerStaking (): void {
    if (this.stakingAmount) {
      // @ts-ignore
      const number = parseFloat(this.stakingAmount).toFixed(4)
      this.stakingAmount = parseFloat(number)
      this.validateStaking()
    }
  }

  // This is gross, needs looking at
  @Watch('withdrawAmount')
  handlerWithdraw (): void {
    if (this.withdrawAmount) {
      // @ts-ignore
      this.canWithdrawal = (this.availableForWithdrawal.sub(BigNumber.from(ethers.utils.parseEther(this.withdrawAmount.toString())))).gte(BigNumber.from(0))
      const number = parseFloat(this.withdrawAmount).toFixed(4)
      this.withdrawAmount = parseFloat(number)
    }
  }

  checkMax (val: number): boolean {
    return val <= this.max
  }

  checkMin (val: number): boolean {
    return val >= this.min
  }

  checkMaxWithdraw (val: number): boolean {
    return (this.availableForWithdrawal.sub(BigNumber.from(ethers.utils.parseEther(val.toString())))).gte(BigNumber.from(0))
  }

  checkMaxValidatorStakeAmount (v: number) {
    const totalPotentialStake = BigNumber.from(ethers.utils.parseEther(v.toString())).add(BigNumber.from(this.validator.serviceProviderBond))
    return BigNumber.from(totalPotentialStake).lt(this.maxStakingAmount)
  }

  handleAmountChange (direction?: string): void {
    if (direction === 'down') {
      if (this.stakingAmount - this.increment > this.min) {
        this.stakingAmount = parseFloat((this.stakingAmount - this.increment).toFixed(4))
      } else {
        this.stakingAmount = this.min
      }
    } else if (direction === 'up') {
      if (this.stakingAmount + this.increment < this.max) {
        this.stakingAmount = parseFloat((this.stakingAmount + this.increment).toFixed(4))
      } else {
      }
    } else if (direction === 'max') {
      this.stakingAmount = this.max
    }
  }

  handleWithdrawAmountChange (direction?: string): void {
    if (direction === 'down') {
      if (this.withdrawAmount - this.increment > this.min) {
        this.withdrawAmount = parseFloat((this.withdrawAmount - this.increment).toFixed(4))
      } else {
        this.withdrawAmount = this.min
      }
    } else if (direction === 'up') {
      if (BigNumber.from(this.withdrawAmount).add(BigNumber.from(this.increment)).lt(this.availableForWithdrawal)) {
        this.withdrawAmount = parseFloat((this.withdrawAmount + this.increment).toFixed(4))
      } else {
      }
    }
  }

  calculateTotalStakedThrough () {
    return this.formatEther(BigNumber.from(this.validator.totalDelegatedStake).sub(BigNumber.from(this.validator.serviceProviderBond)))
  }

  beforeMount (): void {
    this.max = this.cudosBalance
  }

  async mounted (): Promise<void> {
    this.validator = await this.userValidatorObject
    if (!this.validator) {
      await this.$router.push({ name: 'wallet' })
    } else if (!this.validator.isServiceProviderActive && !this.validator.exited) {
      await this.$router.push({ name: 'validatorsBecomeAValidator' })
    } else if (this.validator.exited) {
      await this.$router.push({ name: 'validatorExited' })
    }
    this.serviceProviderContract = await this.connectToServiceProvider(this.validator.id)
    const stakingReward = await this.stakingRewardParams
    this.minStakingAmount = stakingReward.minRequiredStakingAmountForServiceProviders
    this.maxStakingAmount = stakingReward.maxStakingAmountForServiceProviders
    this.availableForWithdrawal = (BigNumber.from(this.validator.serviceProviderBond).sub(BigNumber.from(this.minStakingAmount)))
    this.currentBlock = await this.blockNumber
    this.calculateWithdrawal()
    await this.calculateValidatorReward()
  }

  async calculateValidatorReward () {
    try {
      this.pendingRewards = (await this.serviceProviderContract.pendingRewards(this.validator.serviceProviderManager)).toString()
    } catch (e: any) {
      console.error(e)
      this.pendingRewards = '0'
    }
  }

  calculateWithdrawal () {
    this.withdrawalAmount = this.validator.withdrawalRequestAmount
    this.withdrawalPending = this.currentBlock < this.validator.withdrawalPermittedFrom
  }

  async increaseStake () {
    (this.$refs as any).transactionIndicator.setTransactionFunction(this.increaseStakeFunction)
    await (this.$refs as any).transactionIndicator.callTransactionFunction()
  }

  async increaseStakeFunction (): Promise<void> {
    const {
      cudosToken
    } = this.allContracts

    const hashes: any[] = []

    const tokens = ethers.utils.parseEther(this.stakingAmount.toString())

    let tx = await cudosToken.approve(await this.serviceProviderContract.controller(), tokens)
    hashes.push(tx.hash)
    await tx.wait()

    tx = await this.serviceProviderContract.increaseServiceProviderStake(tokens)
    await tx.wait()

    await this.$router.push({ name: 'validatorsIncreaseStakeSuccess', params: { amount: this.stakingAmount, hashes: hashes.join(',') } })
  }

  async requestWithdrawal () {
    (this.$refs as any).transactionIndicator.setTransactionFunction(this.requestWithdrawalFunction)
    await (this.$refs as any).transactionIndicator.callTransactionFunction()
  }

  async requestWithdrawalFunction (): Promise<void> {
    const hashes = []
    const tokens = ethers.utils.parseEther(this.withdrawAmount.toString())
    const tx = await this.serviceProviderContract.requestExcessServiceProviderStakeWithdrawal(tokens)
    hashes.push(tx.hash)
    await tx.wait()

    await this.$router.push({ name: 'validatorsWithdrawal', params: { amount: this.withdrawAmount, hashes: hashes.join(',') } })
  }

  async withdraw () {
    (this.$refs as any).transactionIndicator.setTransactionFunction(this.withdrawFunction)
    await (this.$refs as any).transactionIndicator.callTransactionFunction()
  }

  async withdrawFunction (): Promise<void> {
    const hashes = []
    const tx = await this.serviceProviderContract.withdrawServiceProviderStake()
    hashes.push(tx.hash)
    await tx.wait()

    await this.$router.push({ name: 'walletWithdrawSuccess', params: { amount: this.withdrawalAmount, hashes: hashes.join(','), valView: 'true' } })
  }

  async claimRewards (): Promise<void> {
    (this.$refs as any).transactionIndicator.setTransactionFunction(this.claimRewardsFunction)
    await (this.$refs as any).transactionIndicator.callTransactionFunction()
  }

  async claimRewardsFunction (): Promise<void> {
    const hashes = []
    const tx = await this.serviceProviderContract.getReward()
    hashes.push(tx.hash)
    await tx.wait()

    await this.$router.push({ name: 'walletRewardsSuccess', params: { amount: this.pendingRewards, hashes: hashes.join(','), valView: 'true' } })
  }

  formatEther (gwei: any) {
    if (gwei === '-') {
      return gwei
    }
    return ethers.utils.formatEther(gwei)
  }
}
