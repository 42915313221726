
























import { Component, Vue } from 'vue-property-decorator'
import CudosToken from '@/components/CudosToken.vue'
import { Action, Getter } from 'vuex-class'
import TransactionIndicator from '@/components/TransactionIndicator.vue'

@Component({
  name: 'ValidatorExit',
  components: {
    CudosToken, TransactionIndicator
  }
})
export default class ValidatorExit extends Vue {
  @Getter('userValidatorObject', { namespace: 'wallet' }) userValidatorObject!: any
  @Action('connectToServiceProvider', { namespace: 'wallet' }) connectToServiceProvider!: any

  validator: any = {}

  async mounted (): Promise<void> {
    this.validator = await this.userValidatorObject
    if (!this.validator) {
      await this.$router.push({ name: 'wallet' })
    } else if (!this.validator.isServiceProviderActive && !this.validator.exited) {
      await this.$router.push({ name: 'validatorsBecomeAValidator' })
    }
  }

  async handleExitAsValidator (): Promise<void> {
    await (this.$refs as any).transactionIndicator.callTransactionFunction()
  }

  async handleExitAsValidatorFunction (): Promise<void> {
    const hashes = []
    const serviceProviderContract = await this.connectToServiceProvider(this.validator.id)
    const tx = await serviceProviderContract.exitAsServiceProvider()
    hashes.push(tx.hash)
    await tx.wait()
    await this.$router.push({ name: 'validatorsExitConfirmed', params: { hashes: hashes.join(',') } })
  }
}
