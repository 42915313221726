












































import { Component, Vue } from 'vue-property-decorator'
import CudosToken from '@/components/CudosToken.vue'
import { Action, Getter } from 'vuex-class'
import { BigNumber, ethers } from 'ethers'
import TransactionIndicator from '@/components/TransactionIndicator.vue'
@Component({
  name: 'ValidatorExited',
  components: {
    CudosToken, TransactionIndicator
  }
})
export default class ValidatorExited extends Vue {
  @Getter('userValidatorObject', { namespace: 'wallet' }) userValidatorObject!: any
  @Getter('blockNumber', { namespace: 'wallet' }) blockNumber!: any
  @Action('connectToServiceProvider', { namespace: 'wallet' }) connectToServiceProvider!: any

  loaded = false
  validator: any = {}
  currentBlock = Infinity
  withdrawalAmount: any = '0'
  withdrawalPending = true
  daysUntilWithdraw = 0

  async handleWithdraw (): Promise<void> {
    await (this.$refs as any).transactionIndicator.callTransactionFunction()
  }

  async handleWithdrawFunction (): Promise<void> {
    const serviceProviderContract = await this.connectToServiceProvider(this.validator.id)
    const tx = await serviceProviderContract.withdrawServiceProviderStake()
    await tx.wait()

    await this.$router.push({ name: 'walletWithdrawSuccess', params: { amount: this.withdrawalAmount } })
  }

  async mounted (): Promise<void> {
    this.validator = await this.userValidatorObject
    if (!this.validator) {
      await this.$router.push({ name: 'wallet' })
      return
    }
    this.currentBlock = this.blockNumber
    this.calculateWithdrawal()
    this.loaded = true
  }

  calculateWithdrawal () {
    this.withdrawalAmount = this.validator.withdrawalRequestAmount
    this.withdrawalPending = this.currentBlock < this.validator.withdrawalPermittedFrom
    if (this.withdrawalPending) {
      this.daysUntilWithdraw = Math.ceil((BigNumber.from(this.validator.withdrawalPermittedFrom).sub(BigNumber.from(this.currentBlock)).toNumber()))
    }
  }

  formatEther (gwei: any) {
    if (gwei === '-') {
      return gwei
    }
    return ethers.utils.formatEther(gwei)
  }
}
