






















































import { Component, Vue, Watch } from 'vue-property-decorator'
import CudosToken from '@/components/CudosToken.vue'
import { Getter, Action } from 'vuex-class'
import { Programme } from '@/modules/wallet/store'
import { BigNumber, ethers } from 'ethers'
import TransactionIndicator from '@/components/TransactionIndicator.vue'

const namespace = 'wallet'

@Component({
  name: 'BecomeAValidator',
  components: {
    CudosToken,
    TransactionIndicator
  }
})
export default class BecomeAValidator extends Vue {
  @Getter('cudosBalance', { namespace }) cudosBalance!: number
  @Getter('allContracts', { namespace }) allContracts!: any
  @Getter('userValidatorObject', { namespace: 'wallet' }) userValidatorObject!: any
  @Getter('allProgrammes', { namespace: 'wallet' }) allProgrammes!: any
  @Getter('stakingRewardParams', { namespace: 'wallet' }) stakingRewardParams!: any
  @Action('connectToServiceProvider', { namespace: 'wallet' }) connectToServiceProvider!: any

  amount = 10.0
  min = 2.0
  max = 99
  minStakingAmount = '2000000000000000000000000'
  increment = 0.1
  lockUpPeriod = 3
  validator: any = {}
  programmes: Programme[] = []
  valid = true

  async mounted (): Promise<void> {
    this.validator = await this.userValidatorObject
    if (!this.validator) {
      await this.$router.push({ name: 'wallet' })
    } else if (this.validator.isServiceProviderActive) {
      await this.$router.push({ name: 'validators' })
    }
    this.programmes = await this.allProgrammes
    if (!this.programmes) {
      console.error('no programmes defined!')
      await this.$router.push({ name: 'wallet' })
    }
    const stakingReward = await this.stakingRewardParams
    this.min = parseFloat(stakingReward.minServiceProviderFee) / 100
    this.minStakingAmount = stakingReward.minRequiredStakingAmountForServiceProviders
  }

  rules = [
    (v: number): boolean | string => !!v || 'Amount is required',
    (v: any): boolean | string => (v && this.isNumber(v)) || 'Amount must be a number',
    (v: number): boolean | string => (v && this.checkMax(v)) || 'Too high commission rate',
    (v: number): boolean | string => (v && this.checkMin(v)) || 'Too low commission rate'
  ]

  isNumber (v: string) {
    return !isNaN(v as any) && !isNaN(parseFloat(v))
  }

  canBecomeAValidator (): boolean {
    return ethers.utils.parseEther(this.cudosBalance as any).gte(BigNumber.from(this.minStakingAmount))
  }

  handleAmountChange (direction?: string): void {
    if (direction === 'down') {
      if (this.amount - this.increment > this.min) {
        this.amount = parseFloat((this.amount - this.increment).toFixed(4))
      } else {
        this.amount = this.min
      }
    } else if (direction === 'up') {
      if (this.amount + this.increment < this.max) {
        this.amount = parseFloat((this.amount + this.increment).toFixed(4))
      } else {
        this.amount = this.max
      }
    }
  }

  validate (): boolean {
    const form = this.$refs.form as HTMLFormElement
    return form.validate()
  }

  // This is gross, needs looking at
  @Watch('amount')
  handler (): void {
    if (this.amount) {
      // @ts-ignore
      const number = parseFloat(this.amount).toFixed(2)
      this.amount = parseFloat(number)
    }
    this.validate()
  }

  checkMax (val: number): boolean {
    return val <= this.max
  }

  checkMin (val: number): boolean {
    return val >= this.min
  }

  async handleConfirmValidator (): Promise<void> {
    await this.depositBond()
  }

  async depositBond (): Promise<void> {
    await (this.$refs as any).transactionIndicator.callTransactionFunction()
  }

  async handleConfirmValidatorFunction (): Promise<void> {
    const {
      cudosToken
    } = this.allContracts

    const hashes = []
    const serviceProviderContract = await this.connectToServiceProvider(this.validator.id)
    // approve the 2 million to the staking rewards contract
    let tx = await cudosToken.approve(await serviceProviderContract.controller(), this.minStakingAmount)
    hashes.push(tx.hash)
    await tx.wait()

    tx = await serviceProviderContract.stakeServiceProviderBond(this.programmes[0].index, (this.amount * 100).toString())
    await tx.wait()
    await this.$router.push({
      name: 'validatorsBecomeAValidatorSuccess',
      params: { hashes: hashes.join(',') }
    })
  }

  formatEther (gwei: any) {
    const format = ethers.utils.formatEther(gwei)
    if (format && format.length > 2 && format.slice(format.length - 2, format.length) === '.0') {
      return ethers.utils.commify(format.slice(0, format.length - 2))
    }
    return ethers.utils.commify(format)
  }
}
