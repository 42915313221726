

























import { Component, Vue } from 'vue-property-decorator'
import CudosToken from '@/components/CudosToken.vue'
import { VARIABLES } from '@/variables'
import SuccessTemplate from '@/components/SuccessTemplate.vue'
@Component({
  name: 'IncreaseStakeSuccess',
  components: {
    CudosToken,
    SuccessTemplate
  }
})
export default class IncreaseStakeSuccess extends Vue {
  amount = ''
  url = VARIABLES.ETHERSCAN_URL
  loaded = false
  hashes: any[] = []

  async mounted (): Promise<void> {
    if (!this.$route.params.amount) {
      await this.$router.push('/validators')
    }
    this.amount = this.$route.params.amount
    if (this.$route.params && this.$route.params.hashes) {
      this.hashes = this.$route.params.hashes.split(',')
    }
    this.loaded = true
  }
}
