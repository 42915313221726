

















import { Component, Vue } from 'vue-property-decorator'
import CudosToken from '@/components/CudosToken.vue'
import { VARIABLES } from '@/variables'
import SuccessTemplate from '@/components/SuccessTemplate.vue'

@Component({
  name: 'RequestToBecomeAValidatorSuccess',
  components: {
    CudosToken,
    SuccessTemplate
  }
})
export default class RequestToBecomeAValidatorSuccess extends Vue {
  url = VARIABLES.ETHERSCAN_URL
  loaded = false
  hashes: any[] = []

  mounted (): void {
    if (this.$route.params && this.$route.params.hashes) {
      this.hashes = this.$route.params.hashes.split(',')
    }
    this.loaded = true
  }
}
